import React, { useState, useEffect, useRef } from 'react';
import Layout from '../components/Layout';
import RefDataDate from '../components/RefDataDate';
import Mapbox from '../mapbox'
import LegendCard from '../mapbox/LegendCard'
import Calculate from '../util/calculate';
import './index.css';
import ImageBG from '/images/oosc-bg.jpg';

import TumbonAddr from '../util/TumbonAddr'

import ImagePaza1 from '/images/piza-1.png';
import ImagePaza2 from '/images/piza-2.png';
import ImagePaza3 from '/images/piza-3.png';
import ImagePaza4 from '/images/piza-4.png';
import ImagePaza5 from '/images/piza-5.png';
import ImagePaza6 from '/images/piza-6.png';
import ImagePaza7 from '/images/piza-7.png';
import ImagePaza8 from '/images/piza-8.png';
import Button from '@mui/material/Button';
import ENG_Addrees from '../util/ENG_Addrees'

import { Table } from 'antd';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import HighchartsItemSeries from 'highcharts/modules/item-series'

if (typeof Highcharts === 'object') {
  HighchartsItemSeries(Highcharts);
}

const PageOOSC = (props) => {

  const [filter, setFilter] = React.useState({
    view: 'country',
    type: "school",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
  });
  const [data_map, setDataMap] = useState([])
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#94a3b8',])
  const is_Click = useRef(false);
  const [translate, setTranslate] = useState(null);
  const [lang, setLang] = useState(null);
  // let oosc_survey_3_5 = props.pageContext.data.reduce((acc, value) => acc + value.year2563_oosc_survey_3_5, 0)
  let province = 42
  let schoolVersion1 = 246
  let schoolVersion2 = 407
  let max = Math.max(schoolVersion1, schoolVersion2)
  let percentversion1 = (schoolVersion1 / max) * 100
  let percentversion2 = (schoolVersion2 / max) * 100

  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    footer: ''
  })


  let dataSource = [
    {
      province: 'เชียงใหม่',
      school: 4,
      student: 104,
      student_exjon: 26,
      student_galaxy: 3,
    },
    {
      province: 'เชียงราย',
      school: 9,
      student: 301,
      student_exjon: 19,
      student_galaxy: 11,
    },
    {
      province: 'กรุงเทพมหานคร',
      school: 6,
      student: 241,
      student_exjon: 9,
      student_galaxy: 2,
    },
    {
      province: 'กาฬสินธุ์',
      school: 9,
      student: 276,
      student_exjon: 80,
      student_galaxy: 31,
    },
    {
      province: 'ฉะเชิงเทรา',
      school: 5,
      student: 234,
      student_exjon: 13,
      student_galaxy: 4,
    },
    {
      province: 'ชลบุรี',
      school: 7,
      student: 253,
      student_exjon: 2,
      student_galaxy: 1,
    },
    {
      province: 'นครศรีธรรมราช',
      school: 5,
      student: 127,
      student_exjon: 12,
      student_galaxy: 4,
    },
    {
      province: 'นครสวรรค์',
      school: 4,
      student: 187,
      student_exjon: 16,
      student_galaxy: 5,
    },
    {
      province: 'นนทบุรี',
      school: 3,
      student: 47,
      student_exjon: 0,
      student_galaxy: 0,
    },
    {
      province: 'ภูเก็ต',
      school: 4,
      student: 167,
      student_exjon: 0,
      student_galaxy: 0,
    },
    {
      province: 'มหาสารคาม',
      school: 7,
      student: 258,
      student_exjon: 35,
      student_galaxy: 10,
    },
    {
      province: 'สุราษฎร์ธานี',
      school: 3,
      student: 119,
      student_exjon: 13,
      student_galaxy: 5,
    },
  ]

  console.log(props)

  useEffect(() => {
    let dataContext = dataSource
    let response = onProcessData({
      view: filter['view'],
      type: filter['type'],
      province: filter['province_name'],
      city: filter['city_name'],
      tumbon: filter['tumbon_name'],
    }, convertMapContent(filter, dataContext))
    setDataMap([
      { id: 'border', layer: filter.view, data: response.data },
    ])


    let data_legend = []
    if (lang === 'EN') {
      data_legend = [
        { name: 'Number of school', value: response.data.reduce((acc, cur) => acc + cur.total, 0), unit: 'schools' },
        { name: 'Number of student', value: response.data.reduce((acc, cur) => acc + cur.total, 0), unit: 'students' },
        { name: 'Number of gifted student', value: response.data.reduce((acc, cur) => acc + cur.total, 0), unit: 'students' },
      ]
    } else {
      data_legend = [
        { name: 'จำนวนโรงเรียน', value: response.data.reduce((acc, cur) => acc + cur.total, 0), unit: 'โรงเรียน' },
        { name: 'จำนวนนักเรียน', value: response.data.reduce((acc, cur) => acc + cur.student, 0), unit: 'คน' },
        { name: 'นักเรียนช้างเผือก', value: response.data.reduce((acc, cur) => acc + cur.student_galaxy, 0), unit: 'คน' },
      ]
    }

    setLegendData({
      ...legend_data,
      title: ENG_Addrees(filter.province_name, lang),
      subtitle: ENG_Addrees(filter.city_name, lang),
      data: data_legend,
    })
  }, [filter, lang])

  const convertMapContent = (filter, data) => {
    let res = []
    const province = [...new Set(TumbonAddr.map(item => item.provincename))].map(item => { return { province: item, total: 0, school: 0, student: 0, student_galaxy: 0, } });
    data.forEach((item) => {
      let total = (item[filter.type])
      let count = (item[filter.type])
      if (filter.view === 'country') {
        let index = province.findIndex(ele => ele.province === item.province)
        if (index !== -1) {
          !province[index]['total'] ? province[index]['total'] = item.school : province[index]['total'] += item.school
          !province[index]['school'] ? province[index]['school'] = item.school : province[index]['school'] += item.school
          !province[index]['student'] ? province[index]['student'] = item.student : province[index]['student'] += item.student
          !province[index]['student_galaxy'] ? province[index]['student_galaxy'] = item.student_galaxy : province[index]['student_galaxy'] += item.student_galaxy
        }
        res = province
      }
    })
    return res
  }

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element)
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element[filter.type] / element['total'])) {
        element['percent'] = 0
      } else {
        element['percent'] = ((element[filter.type] / result.map(item => item[filter.type]).reduce((a, b) => a + b, 0)) * 100)
      }
      return element
    })

    let data_color = [
      { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item[filter.type])), count: 0, text: `มาก` },
      { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item[filter.type])), count: 0, text: `ปานกลาง` },
      { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item[filter.type])), count: 0, text: `ปานกลาง` },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ]

    result.forEach(element => {
      element['color'] = _genColorRange(data_color, element[filter.type])
      // element['opacity'] = 0.5
      element['percent'] = parseFloat(element['percent'].toFixed(2))
    })

    let res = { data: result, notic: data_color }
    return res
  }

  return (
    <Layout backgroundImage={`url(${ImageBG})`} Role={['public-login', 'partner', 'eef', 'partner_province']}
      getLang={(translate, lang) => {
        setTranslate(translate)
        setLang(lang)
      }}
    >
      <div>
        <div className='h-[120px]'></div>
        <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-white w-full ">{translate?.PISA_Project_TITLE_1}</h1>
        <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-white w-full "> {translate?.PISA_Project_TITLE_2} </h1>
        <div className="max-w-[1300px] 2xl:max-w-[1400px]  rounded-3xl m-auto p-8 bg-white bordered">
          <div className="font-bold text-2xl mb-2 text-[#0E3165]">{translate?.Project_Description}</div>
          <p className="text-lg mb-2 font-baijam">{translate?.PISA_Project_Description}</p>
          <div className="grid grid-cols-12 gap-2 gap-y-8 w-full mt-8">

            <div className="col-span-12 lg:col-span-7">
              <div className="font-bold text-xl mb-2 text-[#0E3165]">{translate?.Project_Objectives}</div>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-full md:h-[280px] p-1">
                <div className="grid grid-cols-4 gap-x-4 gap-y-4 w-full mt-4">
                  <div className="col-span-4 md:col-span-2 flex flex-col justify-center">
                    <img className="flex self-center h-[95px] xl:h-[120px] mx-5 my-2" src={ImagePaza1} alt="images" />
                    <div className="font-bold text-lg mb-2 text-[#038967] text-center">{translate?.PISA_Project_Objectives_1}</div>
                  </div>
                  <div className="col-span-4 md:col-span-2 flex flex-col justify-center">
                    <img className="flex self-center h-[95px] xl:h-[120px] mx-5 my-2" src={ImagePaza2} alt="images" />
                    <div className="font-bold text-lg mb-2 text-[#038967] text-center">{translate?.PISA_Project_Objectives_2}</div>
                    {/* <div className="font-bold text-xl mb-2 text-[#0E3165] text-center">ความเพียงพอและ<br />คุณภาพของครูผู้สอน</div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 lg:col-span-5">
              <div className="font-bold text-xl mb-2 text-[#0E3165] mt-8 md:mt-0">{translate?.PISA_Project_Operation}</div>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-min md:h-[280px] p-1">
                <div className="flex flex-col p-3">
                  <div className="text-md mb-2 font-baijam">{translate?.PISA_Project_Operation_Description}</div>
                  {/* <div className="font-bold text-lg mb-2 font-baijam text-[#0E3165]">มีวัตถุประสงค์ในการสนับสนุนสถานศึกษาให้เข้าถึงข้อมูลคุณภาพสูงเพื่อเป็นพื้นฐานในการวางแผนการพัฒนาตนเอง เพื่อยกระดับผมสัมฤทธิ์ทางการเรียนรู้อย่างทั่วถึง และสร้างความเสมอภาคในระดับสถานศึกษาอย่างยั่งยืน</div> */}
                </div>
              </div>
            </div>

            <div className="col-span-12 lg:col-span-4">
              <div className="font-bold text-xl mb-2 text-[#0E3165] ">{translate?.PISA_Project_Information}</div>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-min md:h-[220px] p-1">
                <div className="grid grid-cols-6 gap-x-4 gap-y-4 w-full mt-4">
                  <div className="col-span-2 lg:col-span-2 flex flex-col justify-center">
                    <img className="flex self-center h-[75px] xl:h-[90px] mx-5 my-2" src={ImagePaza3} alt="images" />
                    <div className="font-bold text-lg mb-2 text-[#038967] text-center">{translate?.PISA_Project_Information_Reading}</div>
                  </div>
                  <div className="col-span-2 lg:col-span-2 flex flex-col justify-center">
                    <img className="flex self-center h-[75px] xl:h-[90px] mx-5 my-2" src={ImagePaza4} alt="images" />
                    <div className="font-bold text-lg mb-2 text-[#038967] text-center">{translate?.PISA_Project_Information_Math}</div>
                    {/* <div className="font-bold text-xl mb-2 text-[#0E3165] text-center">ความเพียงพอและ<br />คุณภาพของครูผู้สอน</div> */}
                  </div>
                  <div className="col-span-2 lg:col-span-2 flex flex-col justify-center">
                    <img className="flex self-center h-[75px] xl:h-[90px] mx-5 my-2" src={ImagePaza5} alt="images" />
                    <div className="font-bold text-lg mb-2 text-[#038967] text-center">{translate?.PISA_Project_Information_Science}</div>
                    {/* <div className="font-bold text-xl mb-2 text-[#0E3165] text-center">ความเพียงพอและ<br />คุณภาพของครูผู้สอน</div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 lg:col-span-8">
              <div className="font-bold text-xl mb-2 text-[#0E3165]"> {translate?.PISA_Project_Utilizing} </div>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-min md:h-[220px] p-1">
                <div className="grid grid-cols-6 gap-x-4 gap-y-4 w-full mt-4">
                  <div className="col-span-6 md:col-span-2 flex flex-col justify-center">
                    <img className="flex self-center h-[70px] xl:h-[90px] mx-5 my-2" src={ImagePaza6} alt="images" />
                    <div className="font-bold text-md mb-2 text-[#038967] text-center">{translate?.PISA_Project_Utilizing_1}</div>
                  </div>
                  <div className="col-span-6 md:col-span-2 flex flex-col justify-center">
                    <img className="flex self-center h-[70px] xl:h-[90px] mx-5 my-2" src={ImagePaza7} alt="images" />
                    <div className="font-bold text-md mb-2 text-[#038967] text-center">{translate?.PISA_Project_Utilizing_2}</div>
                  </div>
                  <div className="col-span-6 md:col-span-2 flex flex-col justify-center">
                    <img className="flex self-center h-[70px] xl:h-[90px] mx-5 my-2" src={ImagePaza8} alt="images" />
                    <div className="font-bold text-md mb-2 text-[#038967] text-center">{translate?.PISA_Project_Utilizing_3}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12">
              <div className='flex flex-col md:flex-row my-2 justify-between'>
                <div className="font-bold text-xl mb-2 text-[#0E3165]"> {translate?.PISA_MAP}</div>
                <div className='my-2 flex flex-col md:flex-row '>
                  <Button style={{ margin: 4 }} variant={filter.type === 'school' ? "contained" : "outlined"} onClick={() => setFilter({ ...filter, type: 'school' })}>{translate?.school}</Button>
                  <Button style={{ margin: 4 }} variant={filter.type === 'student' ? "contained" : "outlined"} onClick={() => setFilter({ ...filter, type: 'student' })}>{translate?.TOTAL_STUDENTS}</Button>
                  <Button style={{ margin: 4 }} variant={filter.type === 'student_galaxy' ? "contained" : "outlined"} onClick={() => setFilter({ ...filter, type: 'student_galaxy' })}>{translate?.TOTAL_STUDENTS_GALAXY}</Button>
                  {/* <Button style={{ margin: 4 }} variant={filter.type === 'student' ? "contained" : "outlined"} onClick={() => setFilter({ ...filter, type: 'student' })}>{translate?.school}นักเรียนทั้งหมด</Button>
                  <Button style={{ margin: 4 }} variant={filter.type === 'student_galaxy' ? "contained" : "outlined"} onClick={() => setFilter({ ...filter, type: 'student_galaxy' })}>นักเรียนช้างเผือก</Button> */}
                </div>
              </div>
              <div className="relative w-full bg-gray-200" >
                <Mapbox
                  id={'map-pisa'}
                  data={data_map}
                  zoom={5.2}
                  className="fullmap-cct-container"
                  style={"border_basic"}
                  filter={filter}
                  notic_title={'จำนวนโรงเรียนในประเทศไทย'}
                  lang={lang}
                />
                <div className='absolute top-[10px] left-[10px] z-1'>
                  <LegendCard {...legend_data} lang={lang} />
                </div>
                <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
                  <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
                    <span className='font-bold center mx-1 mb-2'>สัดส่วนนักเรียนที่ได้รับทุน</span>
                    <div className='flex'>
                      <div className='flex flex-col'>
                        <div class={`h-3 w-8 md:w-16 bg-[#1b5e20]`}>
                        </div>
                        <div class="font-bold text-left">
                          {translate?.MAX}
                        </div>
                      </div>
                      <div className='flex flex-col'>
                        <div class={`h-3 w-8 md:w-16 bg-[#388e3c]`}>
                        </div>
                      </div>
                      <div className='flex flex-col'>
                        <div class={`h-3 w-8 md:w-16 bg-[#66bb6a]`}>
                        </div>
                      </div>
                      <div className='flex flex-col'>
                        <div class={`h-3 w-8 md:w-16 bg-[#a5d6a7]`}>
                        </div>
                        <div class="font-bold text-right">
                          {translate?.MIN}
                        </div>
                      </div>
                      <div className='ml-4  flex flex-col'>
                        <div class={`h-3 w-12 md:w-16 bg-[#94a3b8]`}>
                        </div>
                        <div class="font-bold text-center">
                          {translate?.NO_DATA}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Table
                size="small"
                bordered
                scroll={{ x: 800, y: 500 }}
                style={{ width: '100%', marginTop: 12 }}
                dataSource={dataSource}
                columns={[
                  {
                    title: translate?.province,
                    dataIndex: 'province',
                    key: 'province',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "leght" }
                        },
                        children: <div>{ENG_Addrees(text, lang)}</div>
                      };
                    },
                    width: 200,
                    sorter: (a, b) => a.province - b.province,
                  },
                  {
                    title: translate?.TABLE_TOTAL_SCHOOLS,
                    dataIndex: 'school',
                    key: 'school',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    width: 200,
                    sorter: (a, b) => a.school - b.school,
                  },
                  {
                    title: translate?.TABLE_TOTAL_STUDENTS,
                    dataIndex: 'student',
                    key: 'student',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    width: 200,
                    sorter: (a, b) => a.student - b.student,
                  },
                  {
                    title: translate?.TABLE_TOTAL_POOREST,
                    dataIndex: 'student_exjon',
                    key: 'student_exjon',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    width: 200,
                    sorter: (a, b) => a.student_exjon - b.student_exjon,
                  },
                  {
                    title: translate?.TABLE_TOTAL_GIFTED_STUDENT,
                    dataIndex: 'student_galaxy',
                    key: 'student_galaxy',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    width: 200,
                    sorter: (a, b) => a.student_galaxy - b.student_galaxy,
                  },
                ]}
                pageSize={100}
                pagination={{
                  pageSize: 100,
                  position: ['none', 'none']
                }}
                summary={() => (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>{translate?.SUM}</Table.Summary.Cell>
                      <Table.Summary.Cell align='right' index={1}>66</Table.Summary.Cell>
                      <Table.Summary.Cell align='right' index={2}>2,314</Table.Summary.Cell>
                      <Table.Summary.Cell align='right' index={3}>225</Table.Summary.Cell>
                      <Table.Summary.Cell align='right' index={4}>76</Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              >
              </Table>

            </div>
            <div className='h-[75px]'> </div>
          </div>
          <RefDataDate
            title=""
            subjectTitle="*ประมวลผล ณ เดือนเมษายน 2565"
          />
        </div>
        <div className='h-[120px]'></div>
      </div>
    </Layout >
  )
}

export default PageOOSC
